import styled from 'styled-components';

import { easeOutTiming } from 'styles/animation';
import { media } from 'styles/mixins';

const Wrapper = styled.div`
  row-gap: 0.25rem;
  display: grid;

  img {
    width: 100%;
    height: 23rem;
    object-fit: cover;
    min-width: 15.625rem;
    border-radius: var(--box-radius);

    ${media.mobile`
      height: 22.25rem;
      min-width: 13.8125rem;
    `}
  }
`;

const Container = styled.div`
  display: grid;

  > * {
    transition: color ${easeOutTiming};
  }

  p {
    font-size: 0.875rem;
    color: var(--secondary-text-color);
  }

  span {
    font-size: 0.75rem;
    color: var(--button-text-color);

    ~ span {
      margin-top: 0.5rem;
      padding-top: 0.5rem;
      border-top: var(--border) solid var(--border-color-05);
    }
  }
`;

export { Wrapper, Container };
