import Photo from 'components/Photo';

const OurTeam = ({ teammates }) => (
  <>
    {teammates.edges.map((teammate, index) => {
      const {
        role,
        fullName,
        information,
        photo: { url },
      } = teammate.node;

      return (
        <li key={index}>
          <Photo
            src={url}
            title={fullName}
            description={role}
            information={information}
          />
        </li>
      );
    })}
  </>
);

export default OurTeam;
