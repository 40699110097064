import { graphql } from 'gatsby';
import { StructuredText } from 'react-datocms';

import { PageWrapper } from 'components/Layout/PageWrapper';
import { HorizontalContainer, TextContainer } from 'components/UI/Containers';
import { HeadingBig } from 'components/UI/Headings';
import OurTeam from 'components/OurTeam';

import { Grid } from 'styles/elements';

const AboutPageTemplate = ({ data, pageContext }) => {
  const {
    datoCmsAboutPage: pageData,
    allDatoCmsTeammate: teammates,
    datoCmsMiscTextString: translations,
  } = data;
  const { title, seo, description } = pageData;
  const { ourTeam } = translations;

  return (
    <PageWrapper
      pageData={pageContext}
      seoTitle={seo?.seoTitle}
      seoDescription={seo?.seoDescription}
      seoImage={seo?.seoImage?.seoImageUrl}
    >
      <HorizontalContainer>
        <HeadingBig>{title}</HeadingBig>
        <section>
          <div>
            <TextContainer>
              <StructuredText data={description} />
            </TextContainer>

            {/* TODO: paste animation below */}
            {/* <div></div> */}
          </div>
        </section>
        <HeadingBig as="h3">{ourTeam}</HeadingBig>
        <section>
          <Grid>
            <OurTeam teammates={teammates} />
          </Grid>
        </section>
      </HorizontalContainer>
    </PageWrapper>
  );
};

export default AboutPageTemplate;

export const query = graphql`
  query AboutPageQuery($locale: String!) {
    datoCmsAboutPage(locale: { eq: $locale }) {
      locale
      seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
      title
      description {
        value
      }
    }
    datoCmsMiscTextString(locale: { eq: $locale }) {
      locale
      ourTeam
    }
    allDatoCmsTeammate(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          fullName
          role
          information
          photo {
            url
            alt
            width
            height
            originalId
            blurhash
          }
        }
      }
    }
  }
`;
