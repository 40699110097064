import { Wrapper, Container } from './styles';

const Photo = ({ src, title, description, information, className }) => (
  <Wrapper className={className}>
    <img src={src} alt={title} />
    {title || description ? (
      <Container>
        <p>{title}</p>
        {description && <span>{description}</span>}
        {information && <span>{information}</span>}
      </Container>
    ) : null}
  </Wrapper>
);

export default Photo;
